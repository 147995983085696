import '../less/style.less';

var $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('angular/angular.js');
require('angular-ui-bootstrap/dist/ui-bootstrap-tpls')

require('../vendor/bootstrap/js/transition.js');
require('../vendor/bootstrap/js/alert.js');
require('../vendor/bootstrap/js/button.js');
require('../vendor/bootstrap/js/carousel.js');
require('../vendor/bootstrap/js/collapse.js');
require('../vendor/bootstrap/js/dropdown.js');
require('../vendor/bootstrap/js/modal.js');
require('../vendor/bootstrap/js/scrollspy.js');
require('../vendor/bootstrap/js/tab.js');
require('../vendor/bootstrap/js/tooltip.js');
require('../vendor/bootstrap/js/popover.js');
require('../vendor/bootstrap/js/affix.js');
require('bootstrap-select/js/bootstrap-select.js');
require('moment/moment.js');